import { RouteComponentProps } from 'react-router-dom';
import {
  routePersons,
  routeInstitutions,
  routeProjects,
  routePublications,
  routeMentorships,
  routeIndustrialProperties,
  routeCollections,
  routeScientificEquipments,
  routeProductServices,
  routeNews,
  routePowerBi,
  routeClassifiers,
  routeResearchInfrastructures,
  routeFundingNews,
} from 'routes';

export type NavLinkProps = {
  title: string;
  path: string;
  icon?: React.ComponentType<RouteComponentProps> | React.ComponentType;
  parentPath?: string;
  inactive?: boolean;
  children?: NavLinkProps[];
};

const navItems: NavLinkProps[] = [
  {
    // t('Persons')  - for translation parser - should be started in new line
    title: 'Persons',
    path: routePersons,
  },
  {
    // t('Institutions')
    title: 'Institutions',
    path: routeInstitutions,
  },
  {
    // t('Projects')
    title: 'Projects',
    path: routeProjects,
  },
  {
    // t('PortalMenuResearchActivity')
    title: 'PortalMenuResearchActivity',
    path: '#',

    children: [
      {
        // t('Publications')
        title: 'Publications',
        path: routePublications,
      },
      {
        // t('Mentorships')
        title: 'Mentorships',
        path: routeMentorships,
      },
      {
        // t('IndustrialProperties')
        title: 'IndustrialProperties',
        path: routeIndustrialProperties,
      },
      {
        // t('Collections')
        title: 'Collections',
        path: routeCollections,
      },
      {
        // t('ScientificEquipments')
        title: 'ScientificEquipments',
        path: routeScientificEquipments,
      },
      {
        // t('ResearchInfrastructure')
        title: 'ResearchInfrastructure',
        path: routeResearchInfrastructures,
      },
      {
        // t('ProductsServices')
        title: 'ProductsServices',
        path: routeProductServices,
      },
      {
        // t('Classifiers')
        title: 'Classifiers',
        path: routeClassifiers,
      },
    ],
  },
  {
    // t('PortalStatistics')
    title: 'PortalStatistics',
    path: routePowerBi,
  },
  {
    // t('News')
    title: 'News',
    path: routeNews,
  },
/*   {
    // t('FundingNewsPortalMenuTitle')
    title: 'FundingNewsPortalMenuTitle', 
    path: routeFundingNews,
  }, */
];
export default navItems;

import React, { FC, ReactElement } from 'react';
import navItems from 'portal-menu';
import { generateKey } from 'shared/functions/generateKey';
import NavItem from './NavItem';

const Navbar: FC = (): ReactElement => {
  const menuItems = navItems;
  return (
    <>
      <nav role="navigation" aria-label="Menu" className="m-t-8 mr-8">
        <ul className="nav col-12 col-md-auto justify-content-lg-center">
          {menuItems.map((item, index) => {
            return <NavItem item={item} key={generateKey(index, 'navItem')} />;
          })}
        </ul>
      </nav>
    </>
  );
};

export default Navbar;

import React, { lazy } from 'react';
import { RouteComponentProps, RouteProps as NativeRouteProps } from 'react-router-dom';
import { BreadcrumbsRoute } from 'use-react-router-breadcrumbs';

const MainPage = lazy(() => import('./scenes/MainPage/MainPage'));
const OverAllPage = lazy(() => import('./scenes/OverAll/OverAllPage'));
const PersonsPage = lazy(() => import('./scenes/Persons/PersonsPage'));
const InstitutionsPage = lazy(() => import('./scenes/Institutions/InstitutionsPage'));
const ProjectsPage = lazy(() => import('./scenes/Projects/ProjectsPage'));
const PublicationsPage = lazy(() => import('./scenes/Publications/PublicationsPage'));
const MentorshipsPage = lazy(() => import('./scenes/Mentorships/MentorshipsPage'));
const IndustrialPropertiesPage = lazy(
  () => import('./scenes/IndustrialProperties/IndustrialPropertiesPage'),
);
const CollectionsPage = lazy(() => import('./scenes/Collections/CollectionsPage'));
const ScientificEquipmentsPage = lazy(
  () => import('./scenes/ScientificEquipments/ScientificEquipmentsPage'),
);
const ProductServicesPage = lazy(() => import('./scenes/ProductServices/ProductServicesPage'));
const ResearchInfrastructuresPage = lazy(
  () => import('./scenes/ResearchInfrastructures/ResearchInfrastructuresPage'),
);

const ArticlePage = lazy(() => import('./scenes/News/NewsDisplayPage'));
const NewsPage = lazy(() => import('./scenes/News/NewsPage'));
const NewsDisplayPage = lazy(() => import('./scenes/News/NewsDisplayPage'));
const FundingNewsPage = lazy(() => import('./scenes/FundingNews/FundingNewsPage'));
const FundingNewsDisplayPage = lazy(() => import('./scenes/FundingNewsDisplay/FundingNewsDisplayPage'));
const PersonPage = lazy(() => import('./scenes/Person/PersonPage'));
const InstitutionPage = lazy(() => import('./scenes/Institution/InstitutionPage'));
const ProjectPage = lazy(() => import('./scenes/Project/ProjectPage'));
const PublicationPage = lazy(() => import('./scenes/Publication/PublicationPage'));
const MentorshipPage = lazy(() => import('./scenes/Mentorship/MentorshipPage'));
const IndustrialPropertyPage = lazy(
  () => import('./scenes/IndustrialProperty/IndustrialPropertyPage'),
);
const CollectionPage = lazy(() => import('./scenes/Collection/CollectionPage'));
const ScientificEquipmentPage = lazy(
  () => import('./scenes/ScientificEquipment/ScientificEquipmentPage'),
);
const ProductServicePage = lazy(() => import('./scenes/ProductService/ProductServicePage'));
const ResearchInfrastructurePage = lazy(
  () => import('./scenes/ResearchInfrastructure/ResearchInfrastructurePage'),
);

const LoginPage = lazy(() => import('./scenes/Login/LoginPage'));
const CreateAccountPage = lazy(() => import('./scenes/Login/CreateAccountPage'));
const LogonTaraPage = lazy(() => import('./scenes/Login/LogonTaraPage'));
const UniIdSession = lazy(() => import('./scenes/Login/UniIdSession'));
const PowerBiPage = lazy(() => import('./scenes/PowerBi/PowerBiPage'));

const ClassifiersPage = lazy(() => import('./scenes/Classifiers/ClassifiersPage'));
const ClassifierPage = lazy(() => import('./scenes/Classifier/ClassifierPage'));
const PublishingHousePage = lazy(() => import('./scenes/Classifier/PublishingHousePage'));
const ProgrammesPage = lazy(() => import('./scenes/Programmes/ProgrammesPage'));
const ProgrammesHierarchicalPage = lazy(
  () => import('./scenes/Programmes/ProgrammesHierarchicalPage'),
);
const TermOfUsePage = lazy(() => import('./scenes/SiteMap/TermOfUsePage'));
const DevelopmentsPage = lazy(() => import('./scenes/SiteMap/DevelopmentsPage'));
const DataProtectionPage = lazy(() => import('./scenes/SiteMap/DataProtectionPage'));
const Page404Component = lazy(() => import('./scenes/404/Page404'));

export type RouteLocationProps = {
  pathname: string;
  search: string;
};

export type RouteProps = NativeRouteProps &
  BreadcrumbsRoute & {
    name: string;
    path: string;
    component: React.ComponentType<RouteComponentProps> | React.ComponentType;
    exact: boolean;
    environment?: string;
  };

type AvailableRouteParams = {
  shipmentId: string;
  priceRequestId: string;
};

export const redirectBaseUrl = '/uuringud';
export const routeOverAll = '/OverAll';
export const routePersons = '/Portal/Persons/Index';
export const routePerson = '/Portal/Persons/Display';
export const routeCv = '/CV';
export const routeInstitutions = '/Portal/Institutions/Index';
export const routeInstitution = '/Portal/Institutions/Display';
export const routeProjects = '/Portal/Projects/Index';
export const routeProjects2 = '/Portal/Projects/Index2';
export const routeProject = '/Portal/Projects/Display';
export const routePublications = '/Portal/Publications/Index';
export const routePublication = '/Portal/Publications/Display';
export const routeMentorships = '/Portal/Mentorships/Index';
export const routeMentorship = '/Portal/Mentorships/Display';
export const routeIndustrialProperties = '/Portal/IndustrialProperties/Index';
export const routeIndustrialProperty = '/Portal/IndustrialProperties/Display';
export const routeCollections = '/Portal/Collections/Index';
export const routeCollection = '/Portal/Collections/Display';
export const routeScientificEquipments = '/Portal/ScientificEquipments/Index';
export const routeScientificEquipment = '/Portal/ScientificEquipments/Display';
export const routeResearchInfrastructures = '/Portal/ResearchInfrastructure/Index';
export const routeResearchInfrastructure = '/Portal/ResearchInfrastructure/Display';
export const routeProductServices = '/Portal/ProductServices/Index';
export const routeProductService = '/Portal/ProductServices/Display';
export const routeNews = '/Portal/News';
export const routeFundingNews = '/Portal/Financing';
export const routeFundingNewsDisplay = '/Portal/Financing/Display';
export const routeClassifiers = '/Portal/Classifiers/Index';
export const routeClassifier = '/Portal/Classifiers/Details';
export const routePublishingHouse = '/PublishingHouse';
export const routeProgrammes = '/Programmes';
export const routeProgrammesHierarchical = '/ProgrammesHierarchical';
export const routeLogin = '/Logon';
export const routeCreateAccount = '/create_account';
export const routeLogonTara = '/Logon/Tara';
export const routeUniIdSession = '/LogOn/UniIdSession';
export const routePowerBi = '/Teadussilm';
export const routePowerBiStats = '/statistika';
export const routeScientists = '/scientists';
export const routeArticles = '/Portal/Article/Index';
export const routeTermOfUse = '/Portal/TermOfUse';
export const routeDevelopments = '/Portal/Developments';
export const routeDataProtection = '/Portal/DataProtection';
export const route404 = '/404';

const routes: RouteProps[] = [
  {
    name: 'mainPage',
    path: `/`,
    exact: true,
    component: MainPage,
  },
  {
    name: 'overallPage',
    path: routeOverAll,
    exact: true,
    component: OverAllPage,
  },
  {
    name: 'overallPageSearch',
    path: `${routeOverAll}/:queryWord`,
    exact: true,
    component: OverAllPage,
  },
  {
    name: 'persons',
    path: routePersons,
    exact: true,
    component: PersonsPage,
  },
  {
    name: 'persons',
    path: `${routePersons}/:queryWord`,
    exact: true,
    component: PersonsPage,
  },
  {
    name: 'person',
    path: `${routePerson}/:guid`,
    exact: true,
    component: PersonPage,
  },
  {
    name: 'person',
    path: `${routePerson}/:guid/:lang`,
    exact: true,
    component: PersonPage,
  },
  {
    name: 'cv',
    path: `${routeCv}/:queryWord`,
    exact: true,
    component: PersonPage,
  },
  {
    name: 'cv',
    path: `${routeCv}/:queryWord/:lang`,
    exact: true,
    component: PersonPage,
  },
  {
    name: 'institutions',
    path: routeInstitutions,
    exact: true,
    component: InstitutionsPage,
  },
  {
    name: 'institutions',
    path: `${routeInstitutions}/:queryWord`,
    exact: true,
    component: InstitutionsPage,
  },
  {
    name: 'institution',
    path: `${routeInstitution}/:guid`,
    exact: true,
    component: InstitutionPage,
  },
  {
    name: 'projects',
    path: routeProjects,
    exact: true,
    component: ProjectsPage,
  },
  {
    name: 'projects',
    path: `${routeProjects}/:queryWord`,
    exact: true,
    component: ProjectsPage,
  },
  {
    name: 'projects',
    path: `${routeProjects}/?queryWord`,
    exact: true,
    component: ProjectsPage,
  },
  {
    name: 'project',
    path: `${routeProject}/:guid`,
    exact: true,
    component: ProjectPage,
  },
  {
    name: 'publications',
    path: routePublications,
    exact: true,
    component: PublicationsPage,
  },
  {
    name: 'publications',
    path: `${routePublications}/:queryWord`,
    exact: true,
    component: PublicationsPage,
  },
  {
    name: 'publication',
    path: `${routePublication}/:guid`,
    exact: true,
    component: PublicationPage,
  },
  {
    name: 'mentorships',
    path: routeMentorships,
    exact: true,
    component: MentorshipsPage,
  },
  {
    name: 'mentorships',
    path: `${routeMentorships}/:queryWord`,
    exact: true,
    component: MentorshipsPage,
  },
  {
    name: 'mentorship',
    path: `${routeMentorship}/:guid`,
    exact: true,
    component: MentorshipPage,
  },
  {
    name: 'industrialproperties',
    path: routeIndustrialProperties,
    exact: true,
    component: IndustrialPropertiesPage,
  },
  {
    name: 'industrialproperties',
    path: `${routeIndustrialProperties}/:queryWord`,
    exact: true,
    component: IndustrialPropertiesPage,
  },
  {
    name: 'industrialproperty',
    path: `${routeIndustrialProperty}/:guid`,
    exact: true,
    component: IndustrialPropertyPage,
  },
  {
    name: 'collections',
    path: routeCollections,
    exact: true,
    component: CollectionsPage,
  },
  {
    name: 'collections',
    path: `${routeCollections}/:queryWord`,
    exact: true,
    component: CollectionsPage,
  },
  {
    name: 'collection',
    path: `${routeCollection}/:guid`,
    exact: true,
    component: CollectionPage,
  },
  {
    name: 'scientificequipments',
    path: routeScientificEquipments,
    exact: true,
    component: ScientificEquipmentsPage,
  },
  {
    name: 'scientificequipments',
    path: `${routeScientificEquipments}/:queryWord`,
    exact: true,
    component: ScientificEquipmentsPage,
  },
  {
    name: 'scientificequipment',
    path: `${routeScientificEquipment}/:guid`,
    exact: true,
    component: ScientificEquipmentPage,
  },
  {
    name: 'productservices',
    path: routeProductServices,
    exact: true,
    component: ProductServicesPage,
  },
  {
    name: 'productservices',
    path: `${routeProductServices}/:queryWord`,
    exact: true,
    component: ProductServicesPage,
  },
  {
    name: 'productservice',
    path: `${routeProductService}/:guid`,
    exact: true,
    component: ProductServicePage,
  },
  {
    name: 'researchInfrastructures',
    path: routeResearchInfrastructures,
    exact: true,
    component: ResearchInfrastructuresPage,
  },
  {
    name: 'researchInfrastructures',
    path: `${routeResearchInfrastructures}/:queryWord`,
    exact: true,
    component: ResearchInfrastructurePage,
  },
  {
    name: 'researchInfrastructure',
    path: `${routeResearchInfrastructure}/:guid`,
    exact: true,
    component: ResearchInfrastructurePage,
  },
  {
    name: 'news',
    path: routeNews,
    exact: true,
    component: NewsPage,
  },
  {
    name: 'news',
    path: `${routeNews}/Display/:guid`,
    exact: true,
    component: NewsDisplayPage,
  },
  {
    name: 'financing',
    path: routeFundingNews,
    exact: true,
    component: FundingNewsPage,
  },
  {
    name: 'financing',
    path:  `${routeFundingNews}/:queryWord`,
    exact: true,
    component: FundingNewsPage,
  },
  {
    name: 'financing',
    path: `${routeFundingNewsDisplay}/:guid`,
    exact: true,
    component: FundingNewsDisplayPage,
  },
  {
    name: 'article',
    path: `${routeArticles}/:guid`,
    exact: true,
    component: ArticlePage,
  },
  {
    name: 'login',
    path: routeLogin,
    exact: true,
    component: LoginPage,
  },
  {
    name: 'createAccount',
    path: routeCreateAccount,
    exact: true,
    component: CreateAccountPage,
  },
  {
    name: 'logonTara',
    path: routeLogonTara,
    exact: true,
    component: LogonTaraPage,
  },
  {
    name: 'uniIdSession',
    path: routeUniIdSession,
    exact: true,
    component: UniIdSession,
  },
  {
    name: 'powerbi',
    path: routePowerBi,
    exact: true,
    component: PowerBiPage,
  },
  {
    name: 'powerbiStats',
    path: routePowerBiStats,
    exact: true,
    component: PowerBiPage,
  },
  {
    name: 'powerbigraph',
    path: `${routePowerBi}/:guid`,
    exact: true,
    component: PowerBiPage,
  },
  {
    name: 'classifiers',
    path: routeClassifiers,
    exact: true,
    component: ClassifiersPage,
  },
  {
    name: 'classifier',
    path: `${routeClassifiers}/:queryWord`,
    exact: true,
    component: ClassifierPage,
  },
  {
    name: 'PublishingHouse',
    path: `${routePublishingHouse}`,
    exact: true,
    component: PublishingHousePage,
  },
  {
    name: 'Programmes',
    path: routeProgrammes,
    exact: true,
    component: ProgrammesPage,
  },
  {
    name: 'ProgrammesHierarchical',
    path: routeProgrammesHierarchical,
    exact: true,
    component: ProgrammesHierarchicalPage,
  },
  {
    name: 'TermOfUse',
    path: routeTermOfUse,
    exact: true,
    component: TermOfUsePage,
  },
  {
    name: 'Developments',
    path: routeDevelopments,
    exact: true,
    component: DevelopmentsPage,
  },
  {
    name: 'DataProtection',
    path: routeDataProtection,
    exact: true,
    component: DataProtectionPage,
  },
  {
    name: '404',
    path: route404,
    exact: true,
    component: Page404Component,
  },
];

export { routes, Page404Component };

export const translationsUrl = '/res/resource.json';

export const personsPortalIndex = '/Portal/Persons/Index';
export const personsPortalSearchUrl = '/Portal/Persons/Search';
export const personsPortalFilterUrl = '/Portal/Persons/GetFilters';
export const personPortalDisplayUrl = '/Portal/Persons/Display';
export const personPortalCvUrl = '/Portal/Persons/CvUrl';
export const personProjectsPortalUrl = '/Portal/Persons/Projects';
export const personRunningProjectsPortalUrl = '/Portal/Persons/RunningProjects';
export const personComponentsPortalUrl = '/Portal/Persons/components';
export const personEndedProjectsPortalUrl = '/Portal/Persons/EndedProjects';
export const personMentorshipsPortalUrl = '/Portal/Persons/Mentorships';
export const personTeachingsPortalUrl = '/Portal/Persons/Teachings';
export const personPublicOutreachesPortalUrl = '/Portal/Persons/PublicOutreaches';
export const personRunningMentorshipsPortalUrl = '/Portal/Persons/RunningMentorships';
export const personFinalizedMentorshipsPortalUrl = '/Portal/Persons/FinalizedMentorships';
export const personPublicationsPortalUrl = '/Portal/Persons/Publications';
export const personIndustrialPropertiesPortalUrl = '/Portal/Persons/IndustrialProperties';
export const personPublicationsGroupedPortalUrl = '/Portal/Persons/PublicationsGrouped';
export const personPublicationsClassificationTable =
  '/Portal/Persons/PublicationsClassificationTable';

export const personPostdoctoralPortalUrl = '/Portal/Persons/Postdoctoral';
export const personPortalExportFieldsUrl = '/Portal/Persons/ExportableFields';
export const personCvPortalExport = '/Portal/Persons/PdfRender';

export const institutionsPortalSearchUrl = '/Portal/Institutions/Search';
export const institutionsPortalFilterUrl = '/Portal/Institutions/GetFilters';
export const institutionPortalDisplayUrl = '/Portal/Institutions/Display';
export const institutionPublicationPortalUrl = '/Portal/Institutions';
export const institutionComponentsPortalUrl = '/Portal/Institutions/Components';

export const projectsPortalSearchUrl = '/Portal/Projects/Search';
export const projectsPortalFilterUrl = '/Portal/Projects/GetFilters';
export const projectsPortalExportFieldsUrl = '/Portal/Projects/ExportableFields';
export const projectPortalDisplayUrl = '/Portal/Projects/Display';

export const publicationPortalSearchUrl = '/Portal/Publications/Search';
export const publicationPortalFilterUrl = '/Portal/Publications/GetFilters';
export const publicationPortalExportFieldsUrl = '/Portal/Publications/ExportableFields';
export const publicationPortalDisplayUrl = '/Portal/Publications/Display';
export const publicationPortalGroupings = '/Portal/Publications/Groupings';
export const publicationPortalGroupingItems = '/Portal/Publications/GroupingItems';

export const mentorshipsPortalSearchUrl = '/Portal/Mentorships/Search';
export const mentorshipsPortalFilterUrl = '/Portal/Mentorships/GetFilters';
export const mentorshipsPortalExportFieldsUrl = '/Portal/Mentorships/ExportableFields';
export const mentorshipPortalDisplayUrl = '/Portal/Mentorships/Display';

export const industrialPropertiesPortalSearchUrl = '/Portal/IndustrialProperties/Search';
export const industrialPropertiesPortalFilterUrl = '/Portal/IndustrialProperties/GetFilters';
export const industrialPropertiesPortalExportFieldsUrl =
  '/Portal/IndustrialProperties/ExportableFields';
export const industrialPropertyPortalDisplayUrl = '/Portal/IndustrialProperties/Display';

export const collectionsPortalSearchUrl = '/Portal/Collections/Search';
export const collectionsPortalFilterUrl = '/Portal/Collections/GetFilters';
export const collectionsPortalExportFieldsUrl = '/Portal/Collections/ExportableFields';
export const collectionPortalDisplayUrl = '/Portal/Collections/Display';
export const collectionPortalDisplayTabsUrl = '/Portal/Collections/DisplayTabs';
export const collectionProjectsPortalUrl = '/Portal/Collections/CollectionProjects';
export const collectionPublicationsPortalUrl = '/Portal/Collections/CollectionPublications';
export const collectionPublicationsGroupedPortalUrl = '/Portal/Collections/GetPublicationsGrouped';
export const collectionPublicationsClassificationTable = '/Portal/Collections/PublicationsClassificationTable';

export const scientificEquipmentsPortalSearchUrl = '/Portal/ScientificEquipments/Search';
export const scientificEquipmentsPortalFilterUrl = '/Portal/ScientificEquipments/GetFilters';
export const scientificEquipmentsPortalExportFieldsUrl =
  '/Portal/ScientificEquipments/ExportableFields';
export const scientificEquipmentPortalDisplayUrl = '/Portal/ScientificEquipments/Display';

export const researchInfrastructurePortalSearchUrl = '/Portal/ResearchInfrastructure/Search';
export const researchInfrastructurePortalFilterUrl = '/Portal/ResearchInfrastructure/GetFilters';
export const researchInfrastructurePortalExportFieldsUrl =
  '/Portal/ResearchInfrastructure/ExportableFields';
export const researchInfrastructurePortalDisplayUrl = '/Portal/ResearchInfrastructure/Display';

export const productServicesPortalSearchUrl = '/Portal/ProductServices/Search';
export const productServicesPortalFilterUrl = '/Portal/ProductServices/GetFilters';
export const productServicesPortalExportFieldsUrl = '/Portal/ProductServices/ExportableFields';
export const productServicePortalDisplayUrl = '/Portal/ProductServices/Display';

export const fundingNewsPortalSearchUrl = '/Portal/FundingNews/Search';
export const fundingNewsPortalFilterUrl = '/Portal/FundingNews/GetFilters';
export const fundingNewsPortalExportFieldsUrl = '/Portal/FundingNews/ExportableFields';
export const fundingNewsPortalDisplayUrl = '/Portal/FundingNews/Display';

export const articlePortalUrl = '/Portal/Article/GetArticleJson';

export const latestNewsPortalUrl = '/Portal/News/GetActive';
export const newsPortalSearchUrl = '/Portal/News/Search';
export const newsPortalFilterUrl = '/Portal/News/GetFilters';
export const newsPortalDisplayUrl = '/Portal/News/Display';
export const newsPortalReadMoreUrl = '/Portal/News/ReadMore';

export const classifierPortalSearchUrl = '/Portal/Classifiers/Search';
export const classifierPortalPublishingHouseDetailsUrl =
  '/Portal/Classifiers/PublishingHouseDetails';
export const classifierPortalFilterUrl = '/Portal/Classifiers/GetFilters';
export const classifierPortalExportUrl = '/Portal/Classifiers/ExportClassifiers';

export const programmesPortalSearchUrl = '/Portal/Classifiers/ProgrammeSearch/';
export const programmesPortalFilterUrl = '/Portal/Classifiers/GetProgrammeFilters/';
export const programmesHierarchicalUrl = '/Portal/Classifiers/GetSubProgrammes';

export const overallPortalSearchUrl = '/Portal/OverAll/Search';
export const overallPortalFilterUrl = '/Portal/OverAll/GetFilters';
export const autocompletePortalSearchUrl = '/Portal/Autocomplete/Search/';
export const hierarchicalSubDataUrl = '/Portal/Autocomplete/SearchSubDataByParentId/';
export const hierarchicalSubDataWithAllParentsUrl = '/Portal/Autocomplete/GetDataWithAllParents/';

export const sitemapContentUrl = '/Portal/Sitemap/GetContentJson';

export const exportGroupedDataUrl = '/Portal/Common/ExportGroupedData';

// test urls in dev, please dont remove
// export const taraUrlPart1 =
// '/oidc/authorize?redirect_uri=http%3a%2f%2fetis2dev.finestmedia.ee%2fLogon%2fTara&scope=openid%20';
// export const taraUrlPart2 =
// '&state=MjZiNTc3MThlNzE2ZTczZjQ1Njg4NDBiZjJkODI2ZmZmNmI4ZDhlZg==&response_type=code&ui_locales=et&nonce=8f28143bd251206b&client_id=etistest';

// tara urls in live, please dont remove
// export const taraUrlPart1 =
//  '/oidc/authorize?redirect_uri=https%3a%2f%2fwww.etis.ee%2fLogon%2fTara&scope=openid%20';
// export const taraUrlPart2 =
//  '&state=N2JmZDkxMmU2MWMzNTNlOWFjNDU1YmY3Y2U4YjhiMmE0MzRkNGJiMg==&response_type=code&ui_locales=et&nonce=b97cb862a064695e&client_id=etis';

export const taraUrlPart1 =
  `/oidc/authorize?redirect_uri=${process.env.REACT_APP_TARA_URL}%2fLogon%2fTara&scope=openid%20`;
export const taraUrlPart2 =
  `&state=N2JmZDkxMmU2MWMzNTNlOWFjNDU1YmY3Y2U4YjhiMmE0MzRkNGJiMg==&response_type=code&ui_locales=et&nonce=b97cb862a064695e&client_id=${process.env.REACT_APP_TARA_ID}`;

export const logonTaraUrl = '/LogOn/TaraJson';
export const logonUserUrl = '/LogOn/UserJson';
export const logonTyUrl = '/LogOn/TyJson';
export const uniIdStartUrl = '/LogOn/UniIdLoginStart';
export const uniIdSession = '/LogOn/UniIdSession';
export const loginFinalizeUrl = '/Logon/ReactLoginFinalize?resultCode=';
export const forgotPasswordJson = '/LogOn/ForgotPasswordJson';

export const userRegisterUrl = '/LogOn/UserRegisterJson';
export const institutionUserRegisterJson = '/LogOn/InstitutionUserRegisterJson';

export const powerBiUrlJsonId = '5e3d2d35-d115-4082-aa4f-92eb54f5eb02';
export const powerBiUrlJson =
  '/Portal/ResearchStatistics/GraphJson/5e3d2d35-d115-4082-aa4f-92eb54f5eb02';
export const powerBiUrlIframe =
  'https://www.etis.ee:1234/?ws=5979fb17-bf83-479f-a23b-e23714e8857c&rp=5184092b-2d79-424b-9e83-6680068e0204&sc=04c7fc3b4dd5749a3dd0&h=4800';
